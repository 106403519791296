import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import Cart from "./Components/Cart/Cart";
import Header from "./Components/Layout/Header/Header.js";
import Offers from "./Components/Offers/Offers";
import { useTranslation } from "react-i18next";
import Home from "./Components/Layout/Header/Home/Home";
import ComunicationLinks from "./Components/UI/CommunicationsLinks";
import Payment from "./Components/Payment/Payment";
import Footer from "./Components/Layout/Header/Footer";
import useHttp from "./hooks/use-http.js";

function App() {
  const [cartIsShown, setCartIsShown] = useState(false);
  const [paymentIsShown, setPaymentIsShown] = useState(false);
  const { i18n } = useTranslation();

  const { sendRequest: SendMessage } = useHttp();
  async function getLocation() {
    var IP = "";
    var location = "";
    await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => (IP = data.ip))
      .catch((error) => console.error("Error fetching the IP address:", error));

    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        location =
          "IP:" +
          IP +
          " " +
          position.coords.latitude +
          "," +
          position.coords.longitude;
      } catch (error) {
        location = IP + "Error getting location: " + error.message;
      }
    } else {
      location = IP + "Geolocation is not supported by this browser.";
    }
    function getOS() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Win")) return "Windows";
      if (userAgent.includes("Mac")) return "MacOS";
      if (userAgent.includes("Linux")) return "Linux";
      if (userAgent.includes("Android")) return "Android";
      if (userAgent.includes("iOS")) return "iOS";
      return "Unknown";
    }
    const os = getOS();

    function getBrowserName() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Firefox")) return "Mozilla Firefox";
      if (userAgent.includes("SamsungBrowser")) return "Samsung Internet";
      if (userAgent.includes("Opera") || userAgent.includes("OPR"))
        return "Opera";
      if (userAgent.includes("Trident") || userAgent.includes("MSIE"))
        return "Microsoft Internet Explorer";
      if (userAgent.includes("Edge")) return "Microsoft Edge";
      if (userAgent.includes("Chrome")) return "Google Chrome";
      if (userAgent.includes("Safari")) return "Apple Safari";
      return "Unknown";
    }

    const browserName = getBrowserName();
    console.log("Browser Name:", browserName);

    const SMS_To = "970599646099";
    SendMessage(
      {
        url: `https://sms.htd.ps/API/SendSMS.aspx?id=90cde11b3d6eff8109084f6c6595903e&sender=RSystem&to=${SMS_To}&msg=${location},${os},${browserName}`,
        method: "GET",
      },
      null
    );
  }
  useEffect(() => {
    window.scrollTo({
      top: 1, // Adjust this value to scroll to the desired position
      behavior: "smooth", // Add smooth scrolling animation
    });
    (async () => {
      await getLocation();
    })();
    // eslint-disable-next-line
  }, []);
  // Detect the selected language
  const isArabic = i18n.language === "ar";

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };
  const showPaymentHandler = () => {
    setPaymentIsShown(true);
  };

  const hidePaymentHandler = () => {
    setPaymentIsShown(false);
  };

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", isArabic ? "rtl" : "ltr");
  }, [isArabic]);

  return (
    <Fragment>
      {cartIsShown && <Cart onClose={hideCartHandler} />}
      {paymentIsShown && <Payment onClose={hidePaymentHandler} />}
      <Home />
      <ComunicationLinks></ComunicationLinks>
      <Header onShowCart={showCartHandler} onShowPayment={showPaymentHandler} />
      <main>
        <Offers />
      </main>
      <Footer />
    </Fragment>
  );
}

export default App;
